import { HTTP } from "./common-service";

export default {
  login(user) {
    return HTTP.post("login", user).then((response) => {
      return response.data;
    });
  },
  getConfiguration(params) {
    return HTTP.post("get-configuration", params).then((response) => {
      return response.data.data;
    });
  },
  getCompanies(params) {
    return HTTP.post("get-companies", params).then((response) => {
      return response.data;
    });
  },
};
