<template>
  <v-container fluid>
    <ProductsFilter
      @change-client-filter="clientFilterData"
      @update-filter="filterData"
      :validations="validations"
      @clear-data="clearData"
      :type="type"
    />

    <SelectedClientCard :data-list="selectedClientData" />

    <v-card class="mt-4 section-wrap">
      <v-data-table
        :headers="headers"
        :items="paymentData"
        item-key="name"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, 200],
          'items-per-page-text': $t('labels.rows_per_page'),
        }"
        :mobile-breakpoint="0"
        :options.sync="options"
        @current-items="setCurrentItems"
        @update:page="handlePageChange"
      >
        <template v-slot:no-data>
          <div class="px-4">{{ $t("labels.no_data") }}</div>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          <p class="d-flex align-center mb-0">
            {{ formatNumber(item.importe) }}
          </p>
        </template>
        <template v-slot:[`item.importepte`]="{ item }">
          <p class="d-flex align-center mb-0">
            {{ formatNumber(item.importepte) }}
          </p>
        </template>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} de
          {{ items.itemsLength }}
        </template>
        <template v-slot:[`item.selected`]>
          <div class="d-flex align-center">
            <v-checkbox :hide-details="true" :ripple="false"></v-checkbox>
          </div>
        </template>
        <template slot="body.append">
          <tr class="col-total" v-if="currentItems.length > 0">
            <th class="col-total-th" scope="col"></th>
            <th class="col-total-th" scope="col"></th>
            <th class="col-total-th" scope="col"></th>
            <th class="col-total-th" scope="col"></th>
            <th class="col-total-th" scope="col">{{ totalAmountImporte }}</th>
            <th class="col-total-th" scope="col">
              {{ totalAmountImportepte }}
            </th>
            <th class="col-total-th" scope="col"></th>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import ProductsFilter from "../components/ProductsFilter";
import SelectedClientCard from "../components/SelectedClientCard.vue";
import APIServices from "../services/api-services";
import FunctionsUtilities from "@/utilities/functions-utility";
export default {
  name: "PaymentExpiryDate",
  components: {
    ProductsFilter,
    SelectedClientCard,
  },
  data() {
    return {
      max_record: 200,
      validations: [(v) => !!v || this.$t("validations.clientIsRequried")],
      headers: [
        {
          text: this.$t("labels.representant"),
          value: "repre",
        },
        {
          text: this.$t("labels.invoice_date"),
          value: "fecha",
          sort: this.sortDate,
        },
        {
          text: this.$t("labels.date_expiration"),
          value: "fechavto",
          sort: this.sortDate,
        },
        { text: this.$t("labels.invoice"), value: "documento" },
        {
          text: this.$t("labels.total_bill"),
          value: "importe",
          sort: this.sortPrice,
        },
        {
          text: this.$t("labels.import_pending"),
          value: "importepte",
          sort: this.sortPrice,
        },
        { text: this.$t("labels.payment_type"), value: "desc_fpago" },
      ],
      paymentData: [],
      type: "paymentExpireData",
      selectedClientData: [],
      options: {
        itemsPerPage: 200,
        page: 0,
        sortBy: [],
        sortDesc: [],
      },
      currentItems: [],
    };
  },
  mounted() {
    this.setFilterRecords();
  },
  computed: {
    totalAmountImporte() {
      return this.getCurrentPageValueTotal("importe");
    },
    totalAmountImportepte() {
      return this.getCurrentPageValueTotal("importepte");
    },
  },
  methods: {
    sortDate(a, b) {
      return (
        moment(a, "DD/MM/YYYY").toDate() - moment(b, "DD/MM/YYYY").toDate()
      );
    },
    formatNumber(price) {
      return (
        Number(price)
          .toLocaleString("en", {
            useGrouping: true,
            minimumFractionDigits: 2,
          })
          .replace(".", " ")
          .replace(",", ".")
          .replace(" ", ",") + " €"
      );
    },
    filterData(data) {
      this.paymentData = [];
      const filter_data = data;

      APIServices.getPaymentExpireData(
        filter_data,
        this.options.page,
        this.max_record
      ).then(
        (data) => {
          if (data.length) {
            this.paymentData = data;
            this.updateStoreFilterData();
          } else {
            this.paymentData = [];
            this.updateStoreFilterData();
          }
        },
        () => {}
      );
    },
    updateStoreFilterData() {
      FunctionsUtilities.storeFilterData(
        "filter_value_records",
        this.type,
        this.paymentData
      );
    },
    clearData() {
      this.paymentData = [];
      this.updateStoreFilterData();
    },
    setFilterRecords() {
      this.paymentData = FunctionsUtilities.getSelectedRecords(
        "filter_value_records",
        this.type
      );
    },
    getCurrentPageValueTotal(key) {
      return this.formatNumber(
        this.currentItems.reduce(
          (a, b) => parseFloat(a) + parseFloat(b[key] || 0),
          0
        )
      );
    },
    setCurrentItems(value) {
      this.currentItems = value;
    },
    handlePageChange() {
      window.scrollTo(0, 0);
    },
    clientFilterData(data) {
      this.selectedClientData = data ? data : [];
    },
  },
};
</script>
