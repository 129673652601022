<template>
  <div class="d-flex justify-end" :class="fliterActionsClass">
    <v-btn
      @click="clearHandler"
      class="mr-3 greylight4"
      :min-width="120"
      depressed
      large
    >
      {{ $t("labels.cleanup") }}
    </v-btn>
    <v-btn
      class="primary"
      :min-width="120"
      depressed
      large
      @click="filterHandler"
      >{{ $t("labels.filter") }}</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "FilterActions",
  props: {
    clearHandler: {
      type: Function,
      required: true,
    },
    filterHandler: {
      type: Function,
      required: true,
    },
    fliterActionsClass: {
      type: String,
    },
  },
};
</script>
