<template>
  <v-card class="section-wrap mb-4" v-if="calculatorMenu">
    <v-expansion-panels flat v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-filter-variant</v-icon>
            <span class="heading-title-2">{{ $t("labels.calculate") }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-form ref="calculateMarginForm">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                {{ $t("labels.calculate_margin") }}
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <v-text-field
                  v-model="calculator.sellPriceOne"
                  :label="$t('labels.sell_price')"
                  outlined
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="validations"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <v-text-field
                  v-model="calculator.costPriceOne"
                  :label="$t('labels.cost_price')"
                  cost_price
                  outlined
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="validations"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="calulated-results d-flex">
                <div class="result rounded d-flex align-center fill-height">
                  {{ $t("labels.marge") }}
                  {{ percentageOne }} %
                </div>
                <v-btn
                  class="primary mt-1"
                  depressed
                  large
                  max-width="42"
                  min-width="42"
                  max-height="42"
                  min-height="42"
                  @click="calculateMargin()"
                >
                  <v-icon> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="calculateSalePriceForm" class="mt-4">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                {{ $t("labels.sell_price_calculation") }}
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <v-text-field
                  v-model="calculator.costPriceTwo"
                  :label="$t('labels.cost_price')"
                  :validations="validations"
                  outlined
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="validations"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <v-text-field
                  v-model="calculator.percentageTwo"
                  :label="$t('labels.marge_percentage')"
                  :validations="validations"
                  outlined
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="validations"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="calulated-results d-flex">
                <div class="result rounded d-flex align-center fill-height">
                  {{ $t("labels.sell_price") }}
                  {{ salePriceTwo }}
                </div>
                <v-btn
                  class="primary mt-1"
                  depressed
                  large
                  max-width="42"
                  min-width="42"
                  max-height="42"
                  min-height="42"
                  @click="calculateSalePrice()"
                >
                  <v-icon> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: "Calculator",
  props: {
    calculatorMenu: Boolean,
  },
  data() {
    return {
      panel: [0],
      valid: false,
      value: 0,
      percentageOne: 0,
      salePriceTwo: 0,
      calculator: {
        sellPriceOne: 0,
        costPriceOne: 0,
        costPriceTwo: 0,
        percentageTwo: 0,
      },
      validations: [(v) => (v && v > -1) || this.$t("validations.required")],
      mergeValue: null,
    };
  },
  mounted() {},
  methods: {
    calculateMargin() {
      if (this.$refs.calculateMarginForm.validate()) {
        const sellPrice = this.calculator.sellPriceOne;
        const costPrice = this.calculator.costPriceOne;
        const mergeValue = 100 - (costPrice / sellPrice) * 100;
        this.percentageOne =
          mergeValue !== Infinity ? mergeValue.toFixed(2) : 0;
      }
    },
    calculateSalePrice() {
      if (this.$refs.calculateSalePriceForm.validate()) {
        const costPrice = this.calculator.costPriceTwo;
        const percentage = this.calculator.percentageTwo;
        const salePrice = costPrice / (1 - percentage / 100);
        this.salePriceTwo =
          salePrice !== Infinity ? this.formatNumber(salePrice.toFixed(2)) : 0;
      }
    },
    formatNumber(value) {
      return Number(value).toLocaleString("es-ES", {
        style: "currency",
        currency: "EUR",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.calulated-results {
  .result {
    flex: 0 0 180px;
    max-width: 180px;
    margin: 0 15px 0 0;
    padding: 0 10px;
    background: var(--v-whitelight2-base);
    max-height: 50px;
  }
}
</style>
