var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"id":"appBar","app":"","fixed":"","color":"white","clipped-left":_vm.$vuetify.rtl,"clipped-right":!_vm.$vuetify.rtl,"height":"70","flat":""}},[_c('v-btn',{staticClass:"secondary--text",attrs:{"icon":""},on:{"click":() => {
        if (_vm.$vuetify.breakpoint.mdAndDown) {
          _vm.$store.commit('common/TOGGLE_DRAWER');
        } else {
          _vm.$store.commit('common/TOGGLE_DRAWER_MINI');
        }
      }}},[_c('v-icon',{staticClass:"secondary--text"},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',{staticClass:"heading-title-1"},[_vm._v(_vm._s(_vm.$t(`labels.${_vm.getPageName}`)))]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{attrs:{"color":"blackdefault","size":"42"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.user.charAt(0)))])]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.user))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }