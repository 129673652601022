export default {
  TOGGLE_DRAWER_MINI(state) {
    state.mini = !state.mini;
    localStorage.setItem("drawer_mini", state.mini);
  },
  TOGGLE_DRAWER(state) {
    state.drawer = !state.drawer;
  },
  SET_NOTIFICATIONS: (state, notifications) => {
    state.notifications = notifications;
  },
  REMOVE_NOTIFICATIONS: (state) => {
    state.notifications = [];
  },
  ADD_REQUEST: (state) => {
    state.pendingRequestsCount += 1;
  },
  REMOVE_REQUEST: (state) => {
    state.pendingRequestsCount =
      state.pendingRequestsCount > 0 ? state.pendingRequestsCount - 1 : 0;
  },
};
