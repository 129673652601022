import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/auth/Login.vue";
import ClientProduct from "../views/ClientProduct.vue";
import PaymentExpiryDate from "../views/PaymentExpiryDate.vue";
import Stock from "../views/Stock.vue";
import Rates from "../views/Rates.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: "/client-product" };
    },
    component: Home,
  },
  {
    component: Login,
    path: "/login",
    name: "Login",
    meta: {
      fullpage: true,
    },
  },
  {
    component: ClientProduct,
    path: "/client-product",
    name: "ClientProduct",
    meta: {
      fullpage: false,
      auth: true,
      pageName: "customer_statistics",
      class: "customer-statistics",
      breadcrumb: {
        name: "Productes Client",
      },
    },
  },
  {
    component: PaymentExpiryDate,
    path: "/payment-expiry-date",
    name: "PaymentExpiryDate",
    meta: {
      fullpage: false,
      auth: true,
      pageName: "payments",
      class: "payments",
      breadcrumb: {
        name: "Venciments",
      },
    },
  },
  {
    component: Stock,
    path: "/stock",
    name: "stock",
    meta: {
      fullpage: false,
      auth: true,
      pageName: "stock",
      class: "stock",
      breadcrumb: {
        name: "Estoc",
      },
    },
  },
  {
    component: Rates,
    path: "/rates",
    name: "rates",
    meta: {
      fullpage: false,
      auth: true,
      pageName: "rates",
      class: "rates",
      breadcrumb: {
        name: "Tarifes",
      },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next("/login");
    return;
  }
  next();
});
export default router;
