<template>
  <v-container
    id="loginPage"
    class="fill-height d-flex justify-center align-center"
    fluid
  >
    <v-card
      class="pt-9 pb-12"
      :class="$vuetify.breakpoint.smAndUp ? 'px-16' : 'px-8'"
      :max-width="$vuetify.breakpoint.smAndUp ? 546 : '90%'"
      :min-width="$vuetify.breakpoint.smAndUp ? 546 : '90%'"
    >
      <v-img
        src="@/assets/logo_noudata.png"
        class="mx-auto mb-10"
        contain
        width="150"
      />
      <v-form v-model="valid" ref="form">
        <v-select
          :items="companies"
          outlined
          class="mt-4"
          v-model="company"
          :rules="[(v) => !!v || $t('validations.companyIsRequried')]"
          :label="$t('labels.company')"
          hide-details="auto"
          item-value="cdg"
          item-text="descripcion"
        >
        </v-select>
        <v-text-field
          :label="$t('labels.user')"
          v-model="user"
          hide-details="auto"
          outlined
          :rules="[(v) => !!v || $t('validations.userIsRequried')]"
          class="mt-4"
        >
        </v-text-field>
        <v-text-field
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          :rules="[(v) => !!v || $t('validations.passwordIsRequried')]"
          v-model="password"
          :label="$t('labels.password')"
          class="input-group--focused mt-4"
          outlined
          hide-details="auto"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <div class="d-flex justify-center">
          <vue-recaptcha
            class="mt-4 justify-center"
            :sitekey="siteKey"
            ref="recaptcha"
            :loadRecaptchaScript="true"
            @verify="verifyMethod"
            @expired="expiredMethod"
            size="invisible"
          ></vue-recaptcha>
        </div>
        <!-- Recatpure validation for checkbox catpture -->
        <!-- <div
          v-if="!recaptureVaild"
          class="d-flex justify-center v-messages theme--light error--text"
          role="alert"
        >
          <div>{{ $t("validations.recaptureIsRequried") }}</div>
        </div> -->
        <v-btn
          :min-width="$vuetify.breakpoint.smAndUp ? 320 : '80%'"
          depressed
          x-large
          class="mt-6 mx-auto d-block primary"
          @click="login()"
        >
          {{ $t("buttons.login") }}
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import notificationsUtility from "../../utilities/notifications-utility";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Login",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      user: "",
      password: "",
      valid: true,
      companies: [],
      company: null,
      showPassword: false,
      business: null,
      domain: null,
      recaptureVaild: true,
      recaptureToken: null,
    };
  },
  methods: {
    verifyMethod(response) {
      this.recaptureVaild = response ? true : false;
      this.recaptureToken = response;
    },
    expiredMethod() {
      this.recaptureVaild = false;
      this.recaptureToken = null;
    },
    getCompanies() {
      let host =
        process.env.NODE_ENV === "development"
          ? "dev2.test.ndtrace.es"
          : window.location.host;
      var subdomain = host.split(".")[0];
      this.domain = subdomain.toUpperCase();
      localStorage.setItem("domain", this.domain);
      this.$store
        .dispatch("auth/getCompanies", { domain: this.domain })
        .then((response) => {
          this.companies = response;
          localStorage.setItem("companies", JSON.stringify(response));
        });
    },
    login() {
      // this.recaptureVaild = this.recaptureToken ? true : false; // Recatpure validation for checkbox
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("auth/login", {
            username: this.user,
            password: this.password,
            empresa: this.company,
            domain: this.domain,
          })
          .then((response) => {
            if (response.token) {
              localStorage.setItem("token", response.token);
              localStorage.setItem("user", JSON.stringify(response));
              localStorage.setItem(
                "user_data",
                JSON.stringify({ agent: this.user, company_id: this.company })
              );
              this.$store.commit("auth/setAgent", {
                username: this.user,
                empresa: this.company,
              });
              this.$store.commit("auth/setDomain", this.domain);
              localStorage.setItem("filter_values", JSON.stringify({}));
              localStorage.setItem("filter_value_records", JSON.stringify({}));
              notificationsUtility.pushNotifications([
                {
                  message: this.$t("validations.login_success"),
                  type: "success",
                },
              ]);
              this.$router.push({ name: "ClientProduct" });
            } else {
              notificationsUtility.pushNotifications([
                {
                  message: this.$t("validations.check_credentials"),
                  type: "error",
                },
              ]);
            }
          })
          .catch(() => {
            this.$toast.open({
              message: this.$t("validations.somthing_went_wrong"),
              type: "error",
            });
          });
      }
    },
  },
  mounted() {
    this.getCompanies();
    this.$refs.recaptcha.execute();
    this.$store.commit({
      type: "api/resetState",
    });
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: "ClientProduct" });
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
    siteKey() {
      return process.env.VUE_APP_SITE_KEY;
    },
  },
};
</script>
