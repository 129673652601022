<template>
  <v-dialog v-model="modalStatus" scrollable width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon class="mt-2">
        <v-icon>mdi-view-list </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="blackdefault white--text heading-title-3"
        >Població</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-list class="list-modal">
          <v-list-item-group>
            <v-list-item
              v-for="(item, index) in dataSet"
              :key="index"
              class="px-6"
              active-class="bg-active"
            >
              <v-list-item-content class="py-3">
                <v-list-item-title @click="setValue(item)">{{
                  item.descripcion
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          text
          @click="modalStatus = false"
          class="text-uppercase font-weight-medium"
        >
          Tanca
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ListModal",
  props: {
    dataSet: Array,
    type: String,
  },
  data: () => {
    return {
      modalStatus: false,
    };
  },
  methods: {
    setValue(item) {
      this.$emit("set-update", item);
      this.modalStatus = false;
    },
  },
};
</script>
