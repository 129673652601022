<template>
  <v-container fluid class="pt-0">
    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" disabled>
          <v-icon v-if="item.text == 'Home'" color="secondary">mdi-home</v-icon>
          <span v-if="item.text != 'Home'">{{ item.text }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-container>
</template>

<script>
export default {
  name: "Breadcrumb",
  data: () => ({
    breadcrumbs: [],
  }),
  mounted() {
    this.getBreadCrumbs();
  },
  watch: {
    $route() {
      this.getBreadCrumbs();
    },
  },
  methods: {
    getBreadCrumbs() {
      this.breadcrumbs = this.$route.matched.map((route) => {
        return {
          text: route.meta.breadcrumb.name,
          href: `${route.path}`,
        };
      });
      this.breadcrumbs.unshift({
        href: "/",
        text: "Home",
      });
    },
  },
};
</script>
