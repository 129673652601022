import store from "@/store";
export default {
  getStoreData(key) {
    return JSON.parse(localStorage.getItem(key));
  },
  setStoreData(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  },
  storeFilterData(key, type, data) {
    const storeFilterData = this.getStoreData(key);
    storeFilterData[type] = data;
    this.setStoreData(key, storeFilterData);
  },
  getSelectedRecords(key, type) {
    const selectedFilterValues = this.getStoreData(key);
    if (selectedFilterValues[type]) {
      return selectedFilterValues[type];
    }
  },
  setDefaultClient(type) {
    if (!this.getSelectedRecords("filter_value_records", type)) {
      const filterValues = this.getSelectedRecords("filter_values", type);
      if (!filterValues || !filterValues["client"]) {
        const records = this.getSelectedRecords(
          "filter_values",
          this.getClientKeyCombination(type)
        );
        if (records && records["client"]) {
          return records["client"];
        }
      }
    }
  },
  getClientKeyCombination(key) {
    let newKey = "";
    switch (key) {
      case "productData":
        newKey = "paymentExpireData";
        break;
      case "paymentExpireData":
        newKey = "productData";
        break;
      default:
        newKey = "";
        break;
    }
    return newKey;
  },
  checkFilterDataUpdated(key) {
    const currentDate = new Date().toISOString().substr(0, 10);
    return store.state.api.lastUpdate[key] == currentDate;
  },
  sortValue(a, b) {
    const numA = a ? Number(a.replace(",", ".")) : -Infinity;
    const numB = b ? Number(b.replace(",", ".")) : -Infinity;
    return numA - numB;
  },
  sortValueOnly(a, b) {
    const numA = a ? parseFloat(a.replace(/\D/g, "")) : "";
    const numB = b ? parseFloat(b.replace(/\D/g, "")) : "";
    return numA - numB;
  },
};
