import { HTTP } from "./common-service";
import store from "../store";

export default {
  getCities() {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
    };
    return HTTP.post("get-cities", body).then((response) => {
      return response.data;
    });
  },
  getClients(params) {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
      agente: store.state.auth.agent,
    };
    if (params) {
      body.poblacion = params;
    }
    return HTTP.post("get-clients", body).then((response) => {
      return response.data;
    });
  },
  getProductStatistics(params, page) {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
      id_comercial: store.state.auth.agent,
    };
    if (params.client) {
      body.id_cliente = params.client;
    }
    if (params.description) {
      body.desc_producte = params.description;
    }
    return HTTP.post(`get-product-statistics?page=${page}`, body).then(
      (response) => {
        return response.data;
      }
    );
  },
  getPaymentExpireData(params, page) {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
      agente: store.state.auth.agent,
    };
    if (params.client) {
      body.cliente = params.client;
    }
    if (params.description) {
      body.desc_producte = params.description;
    }
    return HTTP.post(`get-payment-expire-data?page=${page}`, body).then(
      (response) => {
        return response.data;
      }
    );
  },
  getArticles() {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
    };
    return HTTP.post("get-articles", body).then((response) => {
      let cdgWithData = [];
      if (response.data) {
        cdgWithData = response.data.map((item) => {
          item["descripcion"] = item.cdg + " " + item.descripcion;
          return item;
        });
      }
      return cdgWithData;
    });
  },
  getManufactures() {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
    };
    return HTTP.post("get-manufactures", body).then((response) => {
      return response.data;
    });
  },
  getFamilies() {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
    };
    return HTTP.post("get-families", body).then((response) => {
      return response.data;
    });
  },

  getStocks(params, page) {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
      agente: store.state.auth.agent,
    };

    if (params.article) {
      body.nom_prod = params.article.toUpperCase();
    }
    if (params.family) {
      body.familia = params.family;
    }
    if (params.manufacturer) {
      body.fabricant = params.manufacturer;
    }
    return HTTP.post(`get-stocks?page=${page}`, body).then((response) => {
      return response.data;
    });
  },

  getRates(params, page) {
    let body = {
      acces_token: store.state.auth.user.token,
      domain: store.state.auth.domain,
      empresa: store.state.auth.company,
      agente: store.state.auth.agent,
    };

    if (params.article) {
      body.nom_prod = params.article.toUpperCase();
    }
    if (params.family) {
      body.familia = params.family;
    }
    if (params.manufacturer) {
      body.fabricant = params.manufacturer;
    }
    return HTTP.post(`get-rates?page=${page}`, body).then((response) => {
      return response.data;
    });
  },
};
