<template>
  <v-navigation-drawer
    id="drawerNav"
    v-model="drawer"
    :right="$vuetify.rtl"
    :mini-variant="drawerMiniVariant"
    mini-variant-width="80"
    app
    width="260"
    v-click-outside="drawerClickOutsideHandler"
  >
    <div class="px-2">
      <v-img
        v-if="!drawerMiniVariant"
        height="98"
        src="@/assets/logo_noudata.png"
        contain
        class="mx-auto my-12"
      ></v-img>
      <v-img
        v-if="drawerMiniVariant"
        height="98"
        src="@/assets/logo_noudata_mini.png"
        contain
        class="mx-auto my-12"
      ></v-img>

      <v-list expand nav v-bind="$attrs" v-on="$listeners">
        <template v-for="(item, i) in navItems">
          <v-list-item
            :to="item.to"
            active-class="primary white--text"
            link
            class="py-1"
            v-bind="$attrs"
            v-on="$listeners"
            :key="`item-${i}`"
          >
            <v-list-item-icon
              v-if="item.icon"
              class="my-2 mx-5 align-self-center"
            >
              <v-icon v-text="item.icon" />
            </v-list-item-icon>

            <v-list-item-content v-if="item.title">
              <v-list-item-title v-text="$t(`labels.${item.title}`)" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </div>

    <template #append>
      <v-list expand nav v-bind="$attrs" v-on="$listeners">
        <template v-for="(item, i) in footerNavItems">
          <v-list-item
            @click="getCickFunction(item.click)"
            link
            class="py-1"
            v-bind="$attrs"
            v-on="$listeners"
            :key="`item-${i}`"
          >
            <v-list-item-icon
              v-if="item.icon"
              class="my-2 mx-5 align-self-center"
            >
              <v-icon v-text="item.icon" />
            </v-list-item-icon>

            <v-list-item-content v-if="item.title">
              <v-list-item-title v-text="$t(`buttons.${item.title}`)" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",

  data: () => {
    return {
      drawer: null,
      navItems: [
        {
          title: "customer_statistics",
          icon: "mdi-chart-box ",
          to: "/client-product",
        },
        {
          title: "payments",
          icon: "mdi-calendar-today",
          to: "/payment-expiry-date",
        },
        {
          title: "stock",
          icon: "mdi-package-variant",
          to: "/stock",
        },
        {
          title: "rates",
          icon: "mdi-percent-box-outline",
          to: "/rates",
        },
      ],
      footerNavItems: [
        {
          title: "logout",
          icon: "mdi-logout-variant ",
          click: "logout",
        },
      ],
    };
  },
  methods: {
    drawerClickOutsideHandler() {
      if (!this.drawerVisible && this.drawerVisible !== null) {
        this.$store.commit({
          type: "common/TOGGLE_DRAWER",
        });
      }
    },
    getCickFunction(data) {
      if (data == "logout") {
        return this.logout();
      }
    },
    logout() {
      this.$store.commit({
        type: "api/resetState",
      });
      localStorage.clear("user");
      localStorage.clear("token");
      localStorage.clear("filter_values");
      localStorage.clear("filter_value_records");
      this.$router.push({ name: "Login" });
    },
  },
  watch: {
    drawerVisible() {
      this.drawer = this.drawerVisible;
    },
  },

  mounted() {
    this.drawer = this.drawerVisible;
  },
  computed: {
    drawerMiniVariant() {
      return this.$store.state.common.mini;
    },
    drawerVisible() {
      return this.$store.state.common.drawer;
    },
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
  },
};
</script>
