<template>
  <v-app-bar
    id="appBar"
    app
    fixed
    color="white"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-btn
      class="secondary--text"
      icon
      @click="
        () => {
          if ($vuetify.breakpoint.mdAndDown) {
            $store.commit('common/TOGGLE_DRAWER');
          } else {
            $store.commit('common/TOGGLE_DRAWER_MINI');
          }
        }
      "
    >
      <v-icon class="secondary--text">mdi-menu</v-icon>
    </v-btn>

    <v-toolbar-title class="heading-title-1">{{
      $t(`labels.${getPageName}`)
    }}</v-toolbar-title>

    <v-spacer />

    <div class="d-flex align-center">
      <v-avatar color="blackdefault" size="42"
        ><span class="white--text">{{ user.charAt(0) }}</span></v-avatar
      >
      <span class="ml-2">{{ user }}</span>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  data() {
    return {};
  },
  computed: {
    getPageName() {
      return this.$route.meta.pageName;
    },
    user() {
      return this.$store.state.auth.user.nom;
    },
  },
};
</script>
