<template>
  <v-container fluid class="pa-0" v-if="this.dataList.descripcion">
    <v-card class="mt-4 section-wrap pa-3">
      <div class="cient-details-wrapper text-center">
        <span class="font-weight-bold">{{ this.mainHeading }}</span>
        {{ this.subHeading }}
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "SelectedClientCard",
  props: ["dataList"],
  data: () => ({
    mainHeading: "",
    subHeading: "",
  }),
  mounted() {
    this.setFilterRecords();
  },
  watch: {
    dataList() {
      this.setFilterRecords();
    },
  },
  methods: {
    setFilterRecords() {
      if (this.dataList) {
        this.mainHeading = this.dataList.descripcion
          ? this.dataList.descripcion
          : "";
        let text = "";
        text += this.dataList.titular
          ? " ( " + this.dataList.titular + " )"
          : "";
        text += this.dataList.direccion ? " - " + this.dataList.direccion : "";
        text += this.dataList.poblacion ? " - " + this.dataList.poblacion : "";
        this.subHeading = text;
      }
    },
  },
};
</script>
