<template>
  <v-container fluid>
    <ProductsFilter
      @change-client-filter="clientFilterData"
      @update-filter="filterData"
      :validations="validations"
      @clear-data="clearData"
      :type="type"
    />

    <SelectedClientCard :data-list="selectedClientData" />

    <v-card class="mt-4 section-wrap">
      <v-data-table
        :headers="headersMain"
        :items="statisticsData"
        item-key="name"
        :items-per-page="200"
        item-class="item_group"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, 200],
          'items-per-page-text': $t('labels.rows_per_page'),
        }"
        :mobile-breakpoint="0"
        :sort-by.sync="sortBy"
        @update:sort-by="sortData"
        @update:page="handlePageChange"
        must-sort
      >
        <template v-slot:no-data>
          <div class="px-4">{{ $t("labels.no_data") }}</div>
        </template>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} de
          {{ items.itemsLength }}
        </template>
        <template v-slot:[`item.selected`]="{ item }">
          <v-checkbox
            v-model="item.selected"
            :hide-details="true"
            :ripple="false"
            @change="changeStatus($event, item)"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import ProductsFilter from "../components/ProductsFilter";
import SelectedClientCard from "../components/SelectedClientCard.vue";
import APIServices from "../services/api-services";
import FunctionsUtilities from "@/utilities/functions-utility";
export default {
  name: "ClientProduct",
  components: {
    ProductsFilter,
    SelectedClientCard,
  },
  data() {
    return {
      moment,
      validations: [(v) => !!v || this.$t("validations.clientIsRequried")],
      page: 0,
      max_record: 20,
      tableItemId: null,
      tableItemGroup: 0,
      statisticsData: [],
      selected: [],
      firstItem: null,
      itemCount: 0,
      classSet: null,
      sortBy: null,
      type: "productData",
      selectedClientData: [],
      headersMain: [
        {
          text: this.$t("labels.id_family"),
          value: "id_familia",
        },
        { text: this.$t("labels.description_family"), value: "desc_familia" },
        { text: this.$t("labels.item_id"), value: "id_producte" },
        { text: this.$t("labels.sel"), value: "selected", sortable: true },
        { text: this.$t("labels.item_description"), value: "desc_producte" },
        {
          text: this.$t("labels.last_sale"),
          value: "ult_compra",
          sort: (a, b) => {
            return (
              moment(a, "DD/MM/YYYY").toDate() -
              moment(b, "DD/MM/YYYY").toDate()
            );
          },
        },
        { text: this.$t("labels.id_fab"), value: "id_fabricant" },
        { text: this.$t("labels.manufacturer"), value: "desc_fabricant" },
      ],
    };
  },
  mounted() {
    this.setFilterRecords();
  },
  methods: {
    filterData(data) {
      this.statisticsData = [];
      this.selected = [];
      const filter_data = data;
      APIServices.getProductStatistics(
        filter_data,
        this.page,
        this.max_record
      ).then(
        (data) => {
          if (data.length) {
            this.tableItemGroup = 0;
            data.forEach((el, index) => {
              el.index = index;
              el.item_group = this.getItemClass(el.id_familia, el.marcat);
              el.selected = false;
            });
            this.statisticsData = data;
            this.updateStoreFilterData();
          } else {
            this.statisticsData = [];
            this.updateStoreFilterData();
          }
        },
        () => {}
      );
    },
    getItemClass(itemId, marcat) {
      if (itemId != this.tableItemId) {
        this.tableItemGroup = !this.tableItemGroup;
      }

      let rowClass = "";
      const marcatStatus = this.itemRowBackground(marcat);
      if (marcatStatus) {
        rowClass += marcatStatus;
      }

      this.tableItemId = itemId;
      rowClass += this.tableItemGroup == 1 ? " group-1" : " group-2";
      return rowClass;
    },
    sortData() {
      if (this.sortBy == "id_familia" || this.sortBy === undefined) {
        this.tableItemGroup = 0;
        this.statisticsData.forEach((el) => {
          el.item_group = this.getItemClass(el.id_familia, el.marcat);
        });
      } else {
        this.statisticsData.forEach((el) => {
          el.item_group = null;
        });
      }
    },
    clearData() {
      this.statisticsData = [];
      this.updateStoreFilterData();
    },
    changeStatus() {
      this.updateStoreFilterData();
    },
    setFilterRecords() {
      this.statisticsData = FunctionsUtilities.getSelectedRecords(
        "filter_value_records",
        this.type
      );
    },
    updateStoreFilterData() {
      FunctionsUtilities.storeFilterData(
        "filter_value_records",
        this.type,
        this.statisticsData
      );
    },
    itemRowBackground(item) {
      return item == "M" ? "row-colored" : "";
    },
    handlePageChange() {
      window.scrollTo(0, 0);
    },
    clientFilterData(data) {
      this.selectedClientData = data ? data : [];
    },
  },
};
</script>
<style scoped>
.section-wrap :deep(tr.row-colored) {
  background: lightblue;
}
</style>
